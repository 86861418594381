import api from "@/store/api";
import moment from 'moment'

const millennium = {
  namespaced: true,
  state: () => ({
    status: 0,
    salesStatus: 0,
    cmvStatus: 0,
    invoicesStatus: 0,
    kardexsStatus: 0,
    kardexAffiliateDefault: 'HUNTES',
    salesChannelMonthTotalStatus: 0,
    salesChannelMonthTotalStatusInfo: 'preparando para obter dados...',
    salesChannelYearMonthTotalStatus: 0,
    auth: {},
    sales: [],
    cmv: [],
    invoices: [],
    kardexs: [],
    affiliates: [],
    brands: [],
    channels: [],
    startDateCMV: `${new Date().getFullYear()}-01-01`,
    endDateCMV: getISODate(new Date().toLocaleDateString()),
    startDate: getISODate(new Date(`${new Date().getFullYear()}-01-01`).toLocaleDateString()),
    endDate: getISODate(new Date().toLocaleDateString()),
    currentStartDate: getISODate(new Date().toLocaleDateString()),
    currentEndDate: getISODate(new Date().toLocaleDateString()),
    previousStartDate: getISODate(moment(new Date()).subtract(1,'days').format('DD/MM/YYYY')),
    previousEndDate: getISODate(moment(new Date()).subtract(1,'days').format('DD/MM/YYYY')),
    salesTotal: {amount: 0, quant: 0, average: 0},
    salesTodayTotalQuant: 0,
    salesTodayTotal: {amount: 0, quant: 0, average: 0, sales: 0},
    salesMonthTotal: {},
    salesLastYears: {},
    salesComparativeTotal: {A: {amount: 0, quant: 0, average: 0}, B: {amount: 0, quant: 0, average: 0}},
    salesFilialsTotal: [],
    salesFilialsComparativeTotal: {A: [], B: []},
    salesChannel: [],
    salesChannelMonthTotal: [],
    salesChannelYearMonthTotal: {},
    months: ['jan','fev','mar','abr','mai','jun','jul','ago','set','out','nov','dez'],
    datetimeUpdate: '',
    comparatives: [],
  }),
  
  mutations: {
    setStartTodayDate: (state) => {
      // state.endDate = state.startDate = getISODate(new Date('2022-01-25').toLocaleDateString())
      state.endDate = state.startDate = getISODate(new Date().toLocaleDateString())
    },
    setAuth: (state, payload) => state.auth = payload,
    setStatus: (state, payload) => state.status = payload,
    setSalesStatus: (state, payload) => state.salesStatus = payload,
    setCMVStatus: (state, payload) => state.cmvStatus = payload,
    setInvoicesStatus: (state, payload) => state.invoicesStatus = payload,
    setKardexsStatus: (state, payload) => state.kardexsStatus = payload,
    setSalesChannelMonthTotalStatus: (state, payload) => state.salesChannelMonthTotalStatus = payload,
    setSalesChannelYearMonthTotalStatus: (state, payload) => state.salesChannelYearMonthTotalStatus = payload,
    setSalesChannelMonthTotalStatusInfo: (state, payload) => state.salesChannelMonthTotalStatusInfo = payload,
    setSales: (state, payload) => state.sales = payload,
    setCMV: (state, payload) => state.cmv = payload,
    setInvoices: (state, payload) => state.invoices = payload,
    setKardexs: (state, payload) => state.kardexs = reducerKardexBriefByYearPerMonths(payload),
    setAffiliates: (state, payload) => state.affiliates = payload,
    setBrands: (state, payload) => state.brands = payload,
    setChannels: (state, payload) => state.channels = payload,
    setStartDate: (state, payload) => state.startDate = payload,
    setEndDate: (state, payload) => state.endDate = payload,
    setCurrentStartDate: (state, payload) => state.currentStartDate = payload,
    setCurrentEndDate: (state, payload) => state.currentEndDate = payload,
    setPreviousStartDate: (state, payload) => state.previousStartDate = payload,
    setPreviousEndDate: (state, payload) => state.previousEndDate = payload,
    setSalesTotal: (state, payload) => state.salesTotal = reducerSalesTotal(payload),
    setSalesTodayTotal: (state, payload) => state.salesTodayTotal = reducerSalesTotal(payload),
    setSalesTodayTotalQuant: (state, payload) => state.salesTodayTotalQuant = payload,
    setSalesMonthTotal: (state, payload) => state.salesMonthTotal = reducerGroupMonths(state.months, payload),
    setSalesLastYears: (state, {year, data}) => state.salesLastYears[year] = reducerGroupMonths(state.months, data),
    setSalesComparativeTotal: (state, payload) =>  state.salesComparativeTotal[payload.key] = reducerSalesTotal(payload[`data${payload.key}`]),
    setComparatives: (state) => reducerSetComparatives(state),
    setSalesChannel: (state, payload) => state.salesChannel = reducerSalesChannel(payload),
    setSalesChannelMonthTotal: (state, payload) => {
      reducerSalesChannelGroupMonths(state.months, payload, res => {
        state.salesChannelMonthTotal = res
      })
    },
    setSalesChannelYearMonthTotal: (state, {year, data}) => {
      reducerSalesChannelGroupMonths(state.months, data, res => {
        state.salesChannelYearMonthTotal[year] = res
      })
    },
    setDateTimeUpdate: (state) => {
      const date = new Date();
      state.datetimeUpdate = date.toLocaleDateString() + ' ' + date.toLocaleTimeString().substr(0,5)
    },
    
    setSalesFilialsTotal: (state, payload) => state.salesFilialsTotal = reducerGroupFilialTotal(payload),
    setSalesFilialsComparativeTotal: (state, payload) => state.salesFilialsComparativeTotal[payload.key] = reducerGroupFilialTotal(payload[`data${payload.key}`])
    
  },
  
  actions: {
    getChannels({commit}) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          api.get(
            `/channels`
          )
            .then(res => {
              const data = res.data.content
              data.sort((a, b) => a.codigo - b.codigo)
              commit('setChannels', data)
              resolve(data)
            })
            .catch(err => {
              reject(err.response ? err.response : err)
            })
        }, 1000)
      })
    },
    getBrands({commit}) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          api.get(
            `/brands`
          )
            .then(res => {
              const data = res.data.content
              data.sort((a, b) => a.cod_marca - b.cod_marca)
              commit('setBrands', data)
              resolve(data)
            })
            .catch(err => {
              reject(err.response ? err.response : err)
            })
        }, 1000)
      })
    },
    getAffiliates({commit}) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          api.get(
            `/affiliates`
          )
            .then(res => {
              const data = res.data.content
              data.sort((a, b) => a.cod_filial - b.cod_filial)
              commit('setAffiliates', data)
              resolve(data)
            })
            .catch(err => {
              reject(err.response ? err.response : err)
            })
        }, 1000)
      })
    },
    getCMV({commit}, {startDate, endDate, affiliates}) {
      return new Promise((resolve, reject) => {
        commit('setCMVStatus', 1)
        const params = {
          startDate,
          endDate,
          affiliates
        }
        setTimeout(() => {
          api.get('/cmv', {params})
            .then(res => {
              const data = res.data.content
              commit('setCMVStatus', 0)
              commit('setCMV', data)
              resolve(data)
            })
            .catch(err => {
              commit('setCMVStatus', -1)
              reject(err.response ? err.response : err)
            })
        }, 1000)
      })
    },
    getInvoicing({commit}, {startDate, endDate, affiliates, brand, channel}) {
      return new Promise((resolve, reject) => {
        commit('setInvoicesStatus', 1)
        const params = {
          startDate,
          endDate,
          affiliates,
          brand,
          channel
        }
        setTimeout(() => {
          api.get('/invoicing', {params})
            .then(res => {
              const data = res.data.content
              commit('setInvoicesStatus', 0)
              commit('setInvoices', data)
              resolve(data)
            })
            .catch(err => {
              commit('setInvoicesStatus', -1)
              reject(err.response ? err.response : err)
            })
        }, 1000)
      })
    },
    getKardexBrief({commit}, {startDate, endDate, affiliate}) {
      return new Promise((resolve, reject) => {
        commit('setKardexsStatus', 1)
        const params = {
          startDate,
          endDate,
          affiliate
        }
        
        setTimeout(() => {
          api.get('/kardex/brief', {params})
            .then(res => {
              const data = res.data.content
              commit('setKardexsStatus', 0)
              commit('setKardexs', data)
              resolve(data)
            })
            .catch(err => {
              commit('setKardexsStatus', -1)
              reject(err.response ? err.response : err)
            })
        }, 1000)
      })
    },
    getKardex({commit}, {startDate, endDate, affiliate}) {
      return new Promise((resolve, reject) => {
        commit('setKardexsStatus', 1)
        const params = {
          startDate,
          endDate,
          affiliate
        }
        setTimeout(() => {
          api.get('/kardex', {params})
            .then(res => {
              const data = res.data.content
              commit('setKardexsStatus', 0)
              commit('setKardexs', data)
              resolve(data)
            })
            .catch(err => {
              commit('setKardexsStatus', -1)
              reject(err.response ? err.response : err)
            })
        }, 1000)
      })
    },
    getSales({commit}, {type, startDate, endDate}) {
      return new Promise((resolve, reject) => {
        commit('setSalesStatus', 1)
        commit('setSalesChannelMonthTotalStatus', 1)
        commit('setSalesChannelYearMonthTotalStatus', 1)
        setTimeout(() => {
          api.get(
            `/sales?startDate=${startDate}&endDate=${endDate}`
          )
            .then(res => {
              const data = res.data.content
              switch (type) {
                case 'today':
                  commit('setSalesTodayTotal', data);
                  commit('setSalesTodayTotalQuant', data.length);
                  commit('setSalesChannel', data);
                  break;
                case 'months':
                  commit('setSalesMonthTotal', data);
                  commit('setSalesChannelMonthTotal', data);
                  break;
                case 'years':
                  commit('setSalesLastYears', {year: startDate.split('-')[0], data});
                  commit('setSalesChannelYearMonthTotal', {year: startDate.split('-')[0], data});
                  break;
                default:
                  commit('setSalesTotal', data)
                  commit('setSalesFilialsTotal', data)
              }
              commit('setSalesStatus', 0)
              resolve(data)
            })
            .catch(err => {
              commit('setSalesStatus', -1)
              reject(err.response ? err.response : err)
            })
        }, 1000)
      })
    },
    getSalesMock({commit, state}, {startDate, endDate}) {
      return new Promise((resolve, reject) => {
        commit('setSalesStatus', 1)
        setTimeout(() => {
          commit('setSalesStatus', 0)
          console.log(startDate, endDate)
          if(state.salesStatus < 0) {
            reject({status: 500})
          } else {
            resolve()
          }
        }, 4000)
        
      })
    },
    getComparativeSales({commit, state}, {currentStartDate, currentEndDate, previousStartDate, previousEndDate}) {
      
      return new Promise((resolve, reject) => {
        commit('setStatus', 1)
        
        api.get(
          `/sales?startDate=${currentStartDate}&endDate=${currentEndDate}`
        )
          .then(res => {
            const dataA = res.data.content
            commit('setSalesComparativeTotal', {key: 'A', dataA})
            commit('setSalesFilialsComparativeTotal', {key: 'A', dataA})
            
            api.get(
              `/sales?startDate=${previousStartDate}&endDate=${previousEndDate}`
            )
              .then(res => {
                const dataB = res.data.content
                resolve(dataA, dataB)
                commit('setStatus', 0)
                commit('setSalesComparativeTotal', {...state.salesComparativeTotal, ...{key: 'B', dataB}})
                commit('setSalesFilialsComparativeTotal', {...state.salesFilialsComparativeTotal, ...{key: 'B', dataB}})
              })
              .catch(err => {
                commit('setStatus', -1)
                reject(err.response ? err.response : err)
              })
          })
          .catch(err => {
            commit('setStatus', -1)
            reject(err.response ? err.response : err)
          })
      })
    },
    getComparativeSalesMock({commit, state}, {currentStartDate, currentEndDate, previousStartDate, previousEndDate}) {
      
      return new Promise((resolve, reject) => {
        commit('setStatus', 1)
        setTimeout(() => {
          commit('setStatus', 0)
          console.log({currentStartDate, currentEndDate, previousStartDate, previousEndDate})
          if(state.status < 0) {
            reject()
          } else {
            resolve()
          }
        }, 2000)
        
      })
    }
  }
}

export default millennium


function reducerSalesTotal(data) {
  return data.reduce((reducer, it) => {
    reducer.amount += it.valor;
    reducer.quant += it.qtde;
    reducer.average = reducer.quant === 0 ? 0 : reducer.amount / reducer.quant
    return reducer;
  },{amount: 0, quant: 0, average: 0, sales: data.length})
}


function reducerGroupMonths(months, data) {
  const totalMonths = {amount: {}, quant: {}, average: {}, sales: {}};
  months.forEach((month,i) => {
    totalMonths.amount[month] = data.reduce((sum,it) => {if(Number(it.mes) === (i+1)) {sum += it.valor} return sum; }, 0)
    totalMonths.sales[month] = data.reduce((sum,it) => {if(Number(it.mes) === (i+1)) {sum = sum+1} return sum; }, 0)
    totalMonths.quant[month] = data.reduce((sum,it) => {if(Number(it.mes) === (i+1)) {sum += it.qtde} return sum; }, 0)
    totalMonths.average[month] = totalMonths.sales[month] === 0 ? 0 : totalMonths.amount[month] / totalMonths.sales[month]
  })
  return totalMonths
}

function reducerSalesChannelGroupMonths(months, data, callback) {
  const totalMonths = {amount: {}};
  months.map((month, i) => {
    const byMonth = data.filter(e => Number(e.mes) === (i+1))
    asyncReducerSalesChannel(byMonth).then(e =>  totalMonths.amount[month] = e)
    if(month === 'dez') callback(totalMonths);
  })
}

function reducerGroupFilialTotal(data) {
  
  return data.reduce((reducer, it) => {
    
    const item = reducer.find(e => e.cod === it.cod_filial) || {cod: 0, amount: 0, qtde: 0, desc: ''};
    
    item.amount += it.valor
    item.qtde += it.qtde;
    
    item.desc = it.nome_filial;
    
    if(item.cod) {
      item.cod = it.cod_filial;
    } else {
      item.cod = it.cod_filial;
      reducer.push(item);
    }
    
    return reducer;
    
  }, [])
}

function reducerSetComparatives(state) {
  const getComparativeValues = (key, indicator) => {
    return state.salesComparativeTotal.length === 0 ? 0.0 : state.salesComparativeTotal[key][indicator]
  }
  
  state.comparatives = [
    {
      title: 'Vendas',
      startDateA: state.currentStartDate,
      endDateA: state.currentEndDate,
      startDateB: state.previousStartDate,
      endDateB: state.previousEndDate,
      amountA: getComparativeValues('A','amount'),
      amountB: getComparativeValues('B','amount'),
      borderColor: '#d58d33'
    },
    {
      title: 'Quantidade',
      startDateA: state.currentStartDate,
      endDateA: state.currentEndDate,
      startDateB: state.previousStartDate,
      endDateB: state.previousEndDate,
      quantA: getComparativeValues('A','quant'),
      quantB: getComparativeValues('B','quant'),
      borderColor: '#68addf'
    },
    {
      title: 'Número de Vendas',
      startDateA: state.currentStartDate,
      endDateA: state.currentEndDate,
      startDateB: state.previousStartDate,
      endDateB: state.previousEndDate,
      quantA: getComparativeValues('A','sales'),
      quantB: getComparativeValues('B','sales'),
      borderColor: '#398137'
    },
    {
      title: 'Tiket Médio',
      startDateA: state.currentStartDate,
      endDateA: state.currentEndDate,
      startDateB: state.previousStartDate,
      endDateB: state.previousEndDate,
      amountA: getComparativeValues('A','average'),
      amountB: getComparativeValues('B','average'),
      borderColor: '#515ebb'
    },
  ]
}

function reducerSalesChannel(data) {
  
  const res = data.reduce((gp, it) => {
    
    // const channelName = it.canal_venda ? it.canal_venda.trim() : it.canal_venda
    const channelName = it.canal_venda ? it.canal_venda.trim() : 'NAO RASTREADO'
    
    // if(!channelName) console.log(it)
    
    const finded = gp.channels.find(e => e.name === channelName)
    
    if(!finded) {
      gp.channels.push({name: channelName, value: parseFloat(Number(it.valor).toFixed(2))})
    } else {
      finded.value = parseFloat(Number(finded.value).toFixed(2)) + parseFloat(Number(it.valor).toFixed(2))
    }
    
    return gp;
  }, {channels: []})
  
  return res.channels
}

function reducerKardexBriefByYearPerMonths(data) {
  
  let item = {year: '', months: {}}
  const years = [];
  const gp = [];
  const lastYear = '';
  
  (new Set(data.map(e => e.ano))).forEach(e => years.push(e));
  
  function setData(year,lastYear, gp, data) {
    
    const allYears = data.filter(e => e.ano === year)
    
    allYears.forEach((it, i) => {
      
      const month = it.mes.toLowerCase()
      item.months[month] = it;
      item.year = it.ano;
      
      if(i === allYears.length - 1) {
        
        gp.push(item);
        
        item = {year: '', months: {}}
        
        if (lastYear !== year) {
          lastYear = year
          
          setData(year,lastYear, gp, data.filter(e => e.ano !== lastYear))
          
        }
        
      }
      
    })
  }
  
  
  years.forEach(year => {
    setData(year,lastYear, gp, data)
  })
  
  
  return gp;
  
}

async function asyncReducerSalesChannel(data) {
  return new Promise((resolve => resolve(reducerSalesChannel(data).filter(e => e.name))))
}


function getISODate(date) {
  return date.split('/').reverse().join('-')
}
